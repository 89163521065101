import ClickableDiv from '../atoms/ClickableDiv';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import URLS from '@/constants/url';
import { Clear } from '@/icons';

const StornoButton: FC = () => {
  const { push } = useRouter();
  return (
    <ClickableDiv
      className="flex items-center text-neutral-gray2 hover:text-neutral-black transition-colors"
      onClick={() => push(URLS.HOMEPAGE)}
      data-id="clear-booking"
    >
      <Clear className="w-2.5 h-2.5 fill-secondary-redwarn" />
      <span className="text-14 leading-none">
        <Trans i18nKey="button.cancel" />
      </span>
    </ClickableDiv>
  );
};

export default StornoButton;
