import StornoButton from './StornoButton';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { useBookingPrice } from '@web/shop-logic';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import Price from '@/components/atoms/Price';
import useModal from '@/components/modal/useModal';
import DiscountModal from '@/components/routePlanner/DiscountModal';
import RoutePlannerItem from '@/components/routePlanner/RoutePlannerItem';
import useCurrentBooking from '@/hooks/useCurrentBooking';
import { Check } from '@/icons';

const RoutePlanner: React.FC = () => {
  const { t } = useTranslation();
  const { totalPrice } = useBookingPrice();
  const { booking, bookingItem } = useCurrentBooking();
  const { showModal } = useModal();

  return booking?.there ? (
    <div className="flex flex-col items-center sticky lg:top-2 sm:bottom-0 sm:mt-2 sm:pt-0">
      <div className="flex flex-col p-2 sm:px-0 w-full rounded-sm border border-neutral-gray3">
        <div className="flex justify-between items-center sm:px-2">
          <h2 className="h2 pt-1 pl-1 sm:hidden">
            <Trans i18nKey="route.planning" />
          </h2>
          <StornoButton />
        </div>
        <div className="sm:mx-1.5">
          {!!booking?.there && <RoutePlannerItem direction="there" booking={booking.there} />}
          {!!booking?.back && (
            <>
              <hr className="my-1 border-neutral-gray3" />
              <RoutePlannerItem direction="back" booking={booking.back} />
            </>
          )}
        </div>
        {/* Skryt celkovou cenu u vyberu tarifu */}
        {(!!totalPrice || bookingItem?.bookingState !== 'ROUTE_SELECTED') && (
          <>
            <hr className="my-2 border-neutral-gray3 lg:-mx-2" />
            <div className="px-2 py-1.5 flex justify-between items-center rounded-sm bg-neutral-graylight sm:mx-2">
              <span className="font-bold">
                <Trans i18nKey="payments.modal.summary.totalPrice" />
                <span className="sr-only">
                  <Price price={totalPrice} />
                </span>
              </span>
              <h3 aria-hidden className="h3 sm:text-h2 sm:text-primary-blue">
                <Price price={totalPrice} />
              </h3>
            </div>
          </>
        )}
      </div>
      {['ADDONS_SELECTED', 'SEAT_SELECTED'].includes(bookingItem?.bookingState) &&
      bookingItem.discountAmount ? (
        <div className="flex items-center gap-0.5 text-14 text-neutral-gray pt-2">
          <Check className="w-2.5 h-2.5 flex-shrink-0 fill-secondary-green" />
          <Trans i18nKey="route.discountApplied" />
        </div>
      ) : (
        <ClickableDiv
          className="flex items-center gap-0.5 text-14 text-neutral-gray pt-2 hover:underline"
          onClick={() =>
            showModal(<DiscountModal />, {
              unmountOnClose: true,
              title: t('route.percentualDiscount.info'),
            })
          }
        >
          <Trans i18nKey="route.applyDiscount" />
        </ClickableDiv>
      )}
    </div>
  ) : null;
};
export default RoutePlanner;
