import { useTranslation } from 'next-i18next';
import React from 'react';
import { SeatPosition } from '@web/shop-logic/dist/hooks/types';
import { groupBy } from '@/utils/arrayUtils';

interface StringifiedSeatsProps {
  seats: SeatPosition[];
  vehicleType: string;
}

const StringifiedSeats: React.FC<StringifiedSeatsProps> = ({ seats = [], vehicleType }) => {
  const { t } = useTranslation();

  const seatGroups = groupBy(seats, (s) => s.vehicleNumber);
  const seatsStrings = Array.from(seatGroups.entries()).map(
    ([vehicleNr, seatPos]) =>
      `${vehicleType !== 'BUS' ? t('seating.vehicle') : ''} ${
        vehicleType !== 'BUS' ? vehicleNr + ',' : ''
      } ${t('ticket.labels.seats')} ${seatPos.map((pos) => pos.seatIndex).join(',')}`,
  );
  return <div>{seatsStrings.join(' ')}</div>;
};

export default StringifiedSeats;
