import React from 'react';
import classNames from 'classnames';
import { ButtonVariants } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';

export enum NavLinkVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const mapBaseClassesToVariant = {
  [ButtonVariants.PRIMARY]: 'h4 border border-t-0 border-l-0 border-r-0 pb-1 mr-5 cursor-pointer',
  [ButtonVariants.SECONDARY]: 'text-primary-blue text-14 px-3 py-1 cursor-pointer font-normal',
};

const mapSelectedClassesToVariant = {
  [ButtonVariants.PRIMARY]: 'text-primary-blue border-primary-blue',
  [ButtonVariants.SECONDARY]: 'bg-primary-blue !text-neutral-white',
};

const mapUnselectedClassesToVariant = {
  [ButtonVariants.PRIMARY]: 'text-neutral-gray2 border-transparent',
  [ButtonVariants.SECONDARY]: 'text-primary-blue',
};
const getClasses = ({ variant, disabled, selected, className }) =>
  classNames(
    disabled && 'pointer',
    mapBaseClassesToVariant[variant],
    selected ? mapSelectedClassesToVariant[variant] : mapUnselectedClassesToVariant[variant],
    className,
  );

interface NavLinkProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  selected: boolean;
  onClick?: () => void;
  disabled?: boolean;
  variant?: `${NavLinkVariants}`;
  className?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  className,
  children,
  variant = NavLinkVariants.PRIMARY,
  selected,
  onClick,
  disabled,
  ...props
}) => (
  <ClickableDiv isClickable={!disabled} onClick={onClick} role="tab" aria-selected={selected}>
    <h4 className={getClasses({ variant, disabled, selected, className })} {...props}>
      {children}
    </h4>
  </ClickableDiv>
);

export default NavLink;
