import Tariff from '../registration/tariffOptions';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { ResponseStateContext, useConnectionRoute } from '@web/shop-logic';
import { BookingProps, ConnectionDirection } from '@web/shop-logic/dist/store/booking/types';
import RoutePlannerItemInner from '@/components/routePlanner/RoutePlannerItemInner';
import URLS from '@/constants/url';
import useContextSelector from '@/hooks/useContextSelector';
import useCurrentBooking from '@/hooks/useCurrentBooking';
import { buildQuery, isQueryValid, mapFareQuery } from '@/utils/routerUtils';

interface RoutePlannerItemProps {
  booking: BookingProps;
  direction: ConnectionDirection;
}

const RoutePlannerItem: React.FC<RoutePlannerItemProps> = ({ booking, direction }) => {
  const { push, query } = useRouter();
  /* useConnectionRoute musi byt zvlast protoze v useCurrentBooking se fetchne jen aktualni. */
  const { data, fetchRoute } = useConnectionRoute();
  const {
    booking: {
      isReturn,
      connection: {
        departureDate,
        fromLocationId,
        fromLocationType,
        returnDepartureDate,
        toLocationId,
        toLocationType,
      },
    },
  } = useCurrentBooking();

  const categoryState = useContextSelector(ResponseStateContext, (c) => c.state[2]);
  const { discountAmount, price, sections, selectedAddons, seatClass, type, priceSource } = booking;

  const stornoInfo = data?.priceClasses.find(
    ({ priceSource: priceCode }) => priceSource === priceCode,
  )?.conditions?.descriptions;

  useEffect(() => {
    const { fromStationId, routeId, toStationId, tariffs } =
      isQueryValid(query, 'fare') && !direction ? mapFareQuery(query) : booking;
    if (!data) fetchRoute({ fromStationId, routeId, toStationId, tariffs });
  }, [data]);

  useEffect(() => {
    const redirectAndBuildQuery = async () => {
      if (categoryState.error?.message) {
        await push({
          pathname: URLS.HOMEPAGE,
          query: buildQuery({
            departureDate,
            returnDepartureDate,
            fromLocationId,
            fromLocationType,
            toLocationId,
            toLocationType,
            tariffs: booking.tariffs,
            isRouteInvalid: 'true',
          }),
        });
      }
    };
    redirectAndBuildQuery();
  }, [categoryState.error?.message]);

  return data ? (
    <RoutePlannerItemInner
      showZtpWarning={!data.nationalTrip && booking.tariffs.includes(Tariff.Disabled)}
      addons={selectedAddons || []}
      price={price + (discountAmount || 0)}
      routeSections={data.sections.map((section) => ({
        section,
        selectedSeats:
          (sections || []).find((bs) => bs.sectionId === section.id)?.selectedSeats || [],
      }))}
      tariffs={booking.tariffs}
      reservationInfo={{ seatClass, ticketType: type, direction: isReturn ? direction : undefined }}
      stornoInfo={stornoInfo}
    />
  ) : null;
};

export default RoutePlannerItem;
