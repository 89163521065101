import TicketConditionLine from '../confirmationTicket/TicketConditionLine';
import useModal from '../modal/useModal';
import Tariff from '../registration/tariffOptions';
import TariffModal from './TariffModal';
import { Trans, useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { utils } from '@web/shop-logic';
import { SeatClass, SeatPosition, TicketType } from '@web/shop-logic/dist/hooks/types';
import { SelectedAddon, TicketAddon } from '@web/shop-logic/dist/hooks/useAddons';
import { Descriptions } from '@web/shop-logic/dist/hooks/useAddons';
import { Section } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import { SupportConnectionBadge } from '@/components/atoms/Badge';
import Chevron from '@/components/atoms/Chevron';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import Price from '@/components/atoms/Price';
import StringifiedSeats from '@/components/atoms/StringifiedSeats';
import StringifiedTariffs from '@/components/atoms/StringifiedTariffs';
import Line from '@/components/connection/Line';
import SeatClassName from '@/components/fare/SeatClassName';
import RouteDirection from '@/components/routePlanner/RouteDirection';
import { ChevronDown } from '@/icons';
import { groupBy } from '@/utils/arrayUtils';
import { getTimeZoneLocalTime } from '@/utils/dateTimeUtils';

const mainLayoutClassNames = 'flex mt-1 justify-between items-center text-14 text-neutral-gray';

interface RoutePlannerItemInnerProps {
  addons: Partial<SelectedAddon & TicketAddon>[];
  codeDiscountPrice?: number;
  direction?: 'back' | 'there';
  percentualDiscountPercentage?: number;
  price: number;
  routeSections: { section: Section; selectedSeats: SeatPosition[] }[];
  tariffs: Tariff[];
  reservationInfo: {
    seatClass: `${SeatClass}`;
    ticketType: TicketType;
    direction?: 'back' | 'there';
  };
  showZtpWarning: boolean;
  disableTariffSelection?: boolean;
  stornoInfo?: Descriptions;
}

const RoutePlannerItemInner: React.FC<RoutePlannerItemInnerProps> = ({
  addons,
  codeDiscountPrice,
  percentualDiscountPercentage,
  price,
  reservationInfo,
  routeSections,
  tariffs,
  showZtpWarning,
  disableTariffSelection,
  stornoInfo,
}) => {
  const { t, i18n } = useTranslation();

  const hasCount = typeof addons[0]?.count === 'number';

  const { showModal } = useModal();

  const openModal = () =>
    showModal(<TariffModal />, { title: t('passenger.change'), deleteBorder: true });

  const addonGroups = !hasCount && Array.from(groupBy(addons, (addon) => addon.name) || []);
  const cleanStornoInfo = [...(stornoInfo?.cancel || '')?.split(', ')].filter(Boolean);

  const [openTransfer, setOpenTransfer] = useState(false);
  const [showStorno, toggleStorno] = useToggle(true);

  return (
    <ul aria-label={t('accessibility.planner.overview')} className="p-1">
      <li className="flex items-center justify-between mb-1.5">
        <RouteDirection direction={reservationInfo.direction} bookingState="ADDONS_SELECTED" />
      </li>
      <li className="text-14 font-bold">
        {new Intl.DateTimeFormat(i18n.language, {
          weekday: 'short',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })?.formatRange?.(
          getTimeZoneLocalTime(routeSections[0].section.departureTime),
          getTimeZoneLocalTime(
            routeSections[routeSections.length - 1].section.estimatedArrivalTime,
          ),
        )}
      </li>
      {routeSections.length > 1 && (
        <>
          <li className="text-14 mt-0.5">
            {routeSections[0].section.departureCityName},{' '}
            {routeSections[0].section.departureStationName}
            {' - '}
            {routeSections[routeSections.length - 1].section.arrivalCityName},{' '}
            {routeSections[routeSections.length - 1].section.arrivalStationName}
          </li>
          <ClickableDiv
            className="mt-0.5 flex items-center gap-0.5 text-14 text-primary-blue hover:underline"
            onClick={() => setOpenTransfer(!openTransfer)}
          >
            <Trans i18nKey="transfer.detail" />
            <Chevron className="mt-[2px]" isOpened={openTransfer} />
          </ClickableDiv>
        </>
      )}
      {(openTransfer || routeSections.length === 1) &&
        routeSections.map(
          ({ section: s, selectedSeats }) =>
            s.id && (
              <li key={s.id} className="my-0.5">
                <Line
                  departureCityName={s.departureCityName}
                  arrivalCityName={s.arrivalCityName}
                  vehicleType={s.vehicleType}
                  lineProps={s.line}
                  sectionId={s.id}
                  fromStationId={s.departureStationId}
                  toStationId={s.arrivalStationId}
                  vehicleStandardKey={s.vehicleStandardKey}
                />
                <div className="flex flex-col gap-0.5 text-14 text-neutral-gray">
                  {s.support && (
                    <SupportConnectionBadge clearTooltip={s.vehicleStandardKey !== 'ECONOMY'} />
                  )}
                  {reservationInfo.seatClass && (
                    <SeatClassName
                      seatClass={reservationInfo.seatClass}
                      standard={s.vehicleStandardKey}
                      type={reservationInfo.ticketType}
                    />
                  )}
                  <StringifiedSeats seats={selectedSeats} vehicleType={s.vehicleType} />
                </div>
              </li>
            ),
        )}
      {disableTariffSelection ? (
        <StringifiedTariffs selectedTariffs={tariffs} showZtpWarning={showZtpWarning} />
      ) : (
        <div className="text-14 flex justify-between items-start">
          <div className="flex flex-col">
            <StringifiedTariffs selectedTariffs={tariffs} showZtpWarning={showZtpWarning} />
          </div>
          <span
            onClick={openModal}
            className="text-primary-blue hover:underline hover:cursor-pointer"
          >
            <Trans i18nKey={'header.title.button.changeButton'} />
          </span>
        </div>
      )}

      {/* Total price for addons */}

      {hasCount &&
        addons.map(({ addonId, id, price: addonPrice, addonCode, name, count }) => (
          <li key={addonId || id} className={mainLayoutClassNames}>
            <div>{`${count}× ${t(utils.getAddonTranslationKey(addonCode || name))}`}</div>
            <div className="font-bold">
              <Price price={count * addonPrice} />
            </div>
          </li>
        ))}
      {!hasCount &&
        addonGroups.map(([addonName, groupAddons]) => (
          <li key={addonName} className={mainLayoutClassNames}>
            <div>{`${groupAddons.length}× ${t(utils.getAddonTranslationKey(addonName))}`}</div>
            <div className="font-bold">
              <Price
                price={groupAddons.reduce((accum, { price: addonPrice }) => accum + addonPrice, 0)}
              />
            </div>
          </li>
        ))}
      {/* Discounts */}
      {percentualDiscountPercentage && (
        <li className={mainLayoutClassNames}>
          <Trans i18nKey="ticket.discount.amount" />{' '}
          <div className="font-bold">
            <Price price={(percentualDiscountPercentage / 100) * price} />
          </div>
        </li>
      )}
      {codeDiscountPrice && (
        <li className={mainLayoutClassNames}>
          <Trans i18nKey="ticket.discount.amount" />
          <div className="font-bold">
            <Price price={codeDiscountPrice} />
          </div>
        </li>
      )}
      {!!cleanStornoInfo?.length && (
        <>
          <li
            onClick={toggleStorno}
            className="text-14 mt-1 text-primary-blue hover:cursor-pointer flex items-center"
          >
            <Trans i18nKey="conditions.title" />
            <ChevronDown
              className={classNames('w-2.5 h-2.5 fill-primary-blue', showStorno && 'rotate-180')}
            />
          </li>
          {showStorno &&
            cleanStornoInfo.map((desc, i) => (
              <TicketConditionLine
                negative={i === cleanStornoInfo.length - 1 && i !== 0}
                key={desc}
                className="text-14 py-0.5"
                iconClasses="w-2.5 h-2.5"
              >
                {desc.trim()}
              </TicketConditionLine>
            ))}
        </>
      )}
    </ul>
  );
};

export default RoutePlannerItemInner;
