import { StationCountry } from '@web/shop-logic/dist/hooks/types';

const domesticCountries = ['CZ', 'SK'];

export const isCorrectCountry = (
  requiredCountry: StationCountry | 'EU' | null,
  stationCountry: StationCountry,
): boolean => {
  if (!requiredCountry) return true;
  if (requiredCountry === 'EU') return !domesticCountries.includes(stationCountry);
  return requiredCountry === stationCountry;
};
