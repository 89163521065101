import { Button } from '../atoms/Button';
import TariffSelect from '../organisms/TariffSelect';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { Form, Formik } from 'formik';
import { Tariff } from '@web/shop-logic/dist/hooks/types';
import ModalContext from '@/components/modal/ModalContext';
import URLS from '@/constants/url';
import useContextSelector from '@/hooks/useContextSelector';
import useCurrentBooking from '@/hooks/useCurrentBooking';
import { buildQuery } from '@/utils/routerUtils';

const TariffsModal: React.FC = () => {
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);
  const { push } = useRouter();

  const {
    booking: { connection = {} },
  } = useCurrentBooking();

  const onSubmit = async (values: { tariffs: Tariff[] }) => {
    await push({
      pathname: URLS.HOMEPAGE,
      query: buildQuery({
        tariffs: values.tariffs,
        fromLocationId: connection.fromLocationId,
        toLocationId: connection.toLocationId,
        departureDate: connection.departureDate,
        ...(connection.returnDepartureDate
          ? { returnDepartureDate: connection.returnDepartureDate }
          : undefined),
        toLocationType: connection.toLocationType,
        fromLocationType: connection.fromLocationType,
      }),
    });
  };

  return (
    <Formik initialValues={{ tariffs: connection.tariffs }} onSubmit={onSubmit}>
      {({ setFieldValue }) => (
        <Form>
          <TariffSelect
            onTariffsChanged={(newTariffs) => setFieldValue('tariffs', newTariffs)}
            isRouter
          />
          <div className="flex justify-end gap-2 pt-1 pr-3">
            <Button onClick={hideAllModals} variant="secondary" size="big">
              <Trans i18nKey="cancel" />
            </Button>
            <Button type="submit" size="big">
              <Trans i18nKey="header.title.button.changeButton" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default TariffsModal;
