import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { BookingState, ConnectionDirection } from '@web/shop-logic/dist/store/booking/types';
import { ArrowRightAlt, CheckCircle } from '@/icons';

const getBookingTypeTranslationString = (direction: ConnectionDirection, isReturnTravel = true) => {
  if (isReturnTravel && direction === 'there') return 'connections.there';
  if (isReturnTravel && direction === 'back') return 'connections.back';
  return 'ticket.route';
};

interface RouteDirectionProps {
  direction: ConnectionDirection;
  bookingState?: BookingState;
  className?: string;
  isReturn?: boolean;
}

const RouteDirection: React.FC<RouteDirectionProps> = ({
  direction,
  bookingState = 'ADDONS_SELECTED',
  className,
  isReturn,
}) => (
  <div className={classNames('flex items-center gap-1', className)}>
    {bookingState === 'ADDONS_SELECTED' && <CheckCircle className="w-3 h-3 fill-secondary-green" />}
    <div className="flex items-center text-secondary-green font-bold uppercase">
      <Trans i18nKey={getBookingTypeTranslationString(direction, isReturn)} />
      <ArrowRightAlt
        className={classNames(
          'w-3 h-3 ml-0.5 fill-current',
          direction === 'back' && 'transform rotate-180',
        )}
      />
    </div>
  </div>
);

export default RouteDirection;
