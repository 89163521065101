import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useBookingDiscount, useLocations, useUser, useUserActions } from '@web/shop-logic';
import Alert from '@/components/atoms/Alert';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import FormInput from '@/components/atoms/FormInput';
import NavLink from '@/components/atoms/NavLink';
import Price from '@/components/atoms/Price';
import RadioCustomControl from '@/components/atoms/RadioCustomControl';
import LoginBox from '@/components/login/LoginBox';
import useModal from '@/components/modal/useModal';
import { isCorrectCountry } from '@/constants/discount';
import useCurrentBooking from '@/hooks/useCurrentBooking';

interface DiscountFormProps {
  type: 'code' | 'percent';
  code: string;
  percentId: number | null;
}

interface DiscountSubmitProps extends Omit<DiscountFormProps, 'code' | 'percentId'> {
  submitted: boolean;
}

const initialValues: DiscountFormProps = {
  type: 'code',
  code: '',
  percentId: null,
};

const DiscountModal: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const {
    percDiscounts: { getPercDiscounts, percDiscountsData = [] },
  } = useUserActions();
  const {
    bookingItem,
    connectionRoute: { fetchRoute, data: connectionRoute },
  } = useCurrentBooking();
  const { selectCodeDiscount, data: discountData, error } = useBookingDiscount();
  const { getStation } = useLocations();
  const { showModal } = useModal();

  const [isSubmitted, setIsSubmitted] = useState<DiscountSubmitProps>({
    submitted: false,
    type: 'code',
  });

  const fromCountry = getStation(bookingItem?.fromStationId)?.countryCode;
  const toCountry = getStation(bookingItem?.toStationId)?.countryCode;
  const fromCityId = getStation(bookingItem?.fromStationId)?.cityId;
  const toCityId = getStation(bookingItem?.toStationId)?.cityId;

  const filteredDiscounts = percDiscountsData.filter(
    (discount) =>
      discount.state === 'VALID' &&
      (!discount.fromStationId || discount.fromStationId === bookingItem?.fromStationId) &&
      (!discount.toStationId || discount.toStationId === bookingItem?.toStationId) &&
      (!discount.fromCityId || discount.fromCityId === fromCityId) &&
      (!discount.toCityId || discount.toCityId === toCityId) &&
      (!discount.fromCountry || isCorrectCountry(discount.fromCountry, fromCountry)) &&
      (!discount.toCountry || isCorrectCountry(discount.toCountry, toCountry)) &&
      (!discount.dateFrom ||
        new Date(discount.dateFrom).valueOf() <
          new Date(connectionRoute?.departureTime).valueOf()) &&
      (!discount.dateTo ||
        new Date(discount.dateTo).valueOf() > new Date(connectionRoute?.departureTime).valueOf()) &&
      discount.passengers >= bookingItem?.tariffs?.length,
  );

  // filteredDiscount didnt work because we werent fetching connectionRoute at some pages.
  // We cant fetch it from query params so instead we use booking params
  useEffect(() => {
    if (!bookingItem) return;

    const params = {
      fromStationId: bookingItem?.fromStationId,
      routeId: bookingItem?.routeId,
      tariffs: bookingItem?.tariffs,
      toStationId: bookingItem?.toStationId,
    };

    fetchRoute(params);
  }, [!!bookingItem]);

  const appliedDiscounts = (percentId: number) =>
    percDiscountsData.filter(({ id }) => id === percentId);

  useEffect(() => {
    if (user.user) getPercDiscounts();
  }, [user.user]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ type, code, percentId }) => {
        await selectCodeDiscount(type === 'code' ? code : percentId);
        setIsSubmitted({ submitted: true, type });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="flex">
            <NavLink
              disabled={isSubmitted.submitted && isSubmitted.type === 'percent'}
              selected={values.type === 'code'}
              onClick={() => setFieldValue('type', 'code')}
            >
              <Trans i18nKey="input.discount" />
            </NavLink>
            {!!filteredDiscounts.length && (
              <NavLink
                disabled={isSubmitted.submitted && isSubmitted.type === 'code'}
                selected={values.type === 'percent'}
                onClick={() => setFieldValue('type', 'percent')}
              >
                <Trans i18nKey="reservation.priceCollapseModal.percentualDiscount" />
              </NavLink>
            )}
          </div>
          {/*Sleva uplatněna*/}
          {isSubmitted.submitted ? (
            <React.Fragment>
              <Alert variant="confirmed" className="my-2">
                <Trans i18nKey={`ticket.discount.${isSubmitted.type}.successful`} />
              </Alert>
              {isSubmitted.type === 'code' && (
                <div>
                  <Trans i18nKey="ticket.discount.amount.title" />:{' '}
                  <Price price={bookingItem?.discountAmount} />
                </div>
              )}
              {isSubmitted.type === 'percent' && (
                <div>
                  {appliedDiscounts(values.percentId).map(({ id, percentage, passengers }) => (
                    <div key={id}>
                      <div className="font-bold">
                        <Trans i18nKey="ticket.discount.amount" /> {percentage}%
                      </div>
                      <div>
                        <Trans i18nKey="ticket.discount.amount.title" />:{' '}
                        <Price price={discountData.amount} />
                      </div>
                      <div>
                        <Trans i18nKey="ticket.discount.passengers" />: {passengers}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {values.type === 'code' && (
                <div>
                  {error?.response?.data?.message && (
                    <Alert variant="warning" className="mt-2">
                      {error?.response?.data?.message}
                    </Alert>
                  )}
                  <div className="mt-2">
                    <Trans i18nKey="reservation.discount.description" />
                  </div>
                  <div className="mt-1">
                    <FormInput name="code" fullWidth label={t('input.discount')} />
                  </div>
                </div>
              )}
              {values.type === 'percent' && (
                <div className="flex flex-col pt-2">
                  {user.user ? (
                    <>
                      <Trans i18nKey="route.percentualDiscount.title" />
                      {filteredDiscounts.map(({ id, percentage, passengers }) => (
                        <RadioCustomControl
                          key={id}
                          value={id.toString()}
                          checked={values.percentId === id}
                          onChange={() => setFieldValue('percentId', id)}
                          name="percentId"
                        >
                          <div className="flex flex-col pt-2">
                            <div className="font-bold">
                              <Trans i18nKey="ticket.discount.amount" /> {percentage}%
                            </div>
                            <div>
                              <Trans i18nKey="ticket.discount.passengers" />: {passengers}
                            </div>
                          </div>
                        </RadioCustomControl>
                      ))}
                    </>
                  ) : (
                    <Alert>
                      <Trans i18nKey="route.percentualDiscount.notAvailable" />
                      <ClickableDiv
                        className="hover:underline"
                        onClick={() => showModal(<LoginBox />, { size: 'big' })}
                      >
                        <Trans i18nKey="settings.mojeid.login" />
                      </ClickableDiv>
                    </Alert>
                  )}
                </div>
              )}
              <div className="flex justify-end mt-3">
                <Button
                  disabled={
                    (values.type === 'code' && !values.code) ||
                    (values.type === 'percent' && !values.percentId)
                  }
                  type="submit"
                  size="big"
                >
                  <Trans i18nKey="reservation.percentualDiscount.submit" />
                </Button>
              </div>
            </React.Fragment>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DiscountModal;
